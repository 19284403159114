import { useEffect, useState } from 'react';
import firebase from 'firebase';
import 'firebase/analytics';

import "../styles/globals.css";
import "../styles/tailwind.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../styles/icons.css";
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "../styles/index.scss";

// Progress 

import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

function MyApp({ Component, pageProps }) {
    const [ app, setApp ] = useState(null);

    useEffect(() => {
        // Firebase log

        let app = null;

        const logEvent = (url) => {
            if (app !== null && process.env.NODE_ENV === 'production') {
                app.analytics().setCurrentScreen(url);
                app.analytics().logEvent('screen_view');
            }
        };

        if (typeof window !== "undefined" && !firebase.apps.length) {
            console.log('Init Firebase...');

            app = firebase.initializeApp({
                apiKey: "AIzaSyD8juAl9R4WJCZjBF_3uVA9AeVNp_hUThc",
                authDomain: "canal-32-app.firebaseapp.com",
                projectId: "canal-32-app",
                storageBucket: "canal-32-app.appspot.com",
                messagingSenderId: "135748635861",
                appId: "1:135748635861:web:f1d6461f816e475f88e65c",
                measurementId: "G-PHKCVCFS5Y"
            });

            setApp(app);
        }

        // Binding events

        Router.events.on('routeChangeStart', () => NProgress.start());
        Router.events.on('routeChangeError', () => NProgress.done());
        Router.events.on('routeChangeComplete', (url) => {
            NProgress.done();
            logEvent(url);
	    window.sp_track();
        });
        

    }, []);

    useEffect(() => {
        if (typeof window !== "undefined" && window.sp_track) {
            window.sp_track();
        }
    },[]);

    return <Component {...pageProps} app={app} />
}

export default MyApp;
